import styled from '@emotion/styled'
import { Minus, Plus } from 'app/components/Icons'
import { useVocabularyData } from 'app/utils/vocabulary'
import React, { memo, useState } from 'react'

export interface Props {
  description?: string
  languageCode: string
}

export const Terms = memo(function Terms({ description, languageCode }: Props) {
  if (!description) {
    return null
  }

  const [expanded, setExpanded] = useState(false)

  return (
    <Container>
      <Title
        onClick={() => (expanded ? setExpanded(false) : setExpanded(true))}
      >
        {useVocabularyData('terms-conditions', languageCode)}
        {expanded ? <Minus /> : <Plus />}
      </Title>
      <Description
        className={expanded ? 'open' : undefined}
        dangerouslySetInnerHTML={{ __html: description }}
      />
    </Container>
  )
})

const Container = styled.div`
  background: ${({ theme }) => theme.colors.variants.neutralLight4};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  overflow: hidden;
  margin-top: 3.625rem;
`

const Title = styled.h3`
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: ${({ theme }) => theme.colors.variants.neutralDark2};
  cursor: pointer;
  font-size: 0.875rem;
  font-weight: 700;
  letter-spacing: 0.0875rem;
  line-height: 1.25rem;
  padding: 1.5rem 2.125rem;
  text-transform: uppercase;

  svg {
    width: 0.875rem;
    height: auto;
    max-height: 0.875rem;
    stroke: ${({ theme }) => theme.colors.variants.primaryDark1};
    stroke-width: 2;
    transform: translateY(0.0625rem);
  }
`

const Description = styled.div`
  height: 0;
  color: ${({ theme }) => theme.colors.variants.neutralDark5};
  font-size: 1.125rem;
  font-weight: 300;
  line-height: 2rem;
  &.open {
    height: auto;
    padding: 0 2.125rem 1.875rem;
  }

  p {
    margin-block-end: 1em;
  }
  ul {
    margin-bottom: 1em;
    padding: inherit;
    li {
      padding-left: 1.25rem;
      position: relative;
      &:before {
        content: '';
        width: 0.375rem;
        height: 0.375rem;
        background: ${({ theme }) => theme.colors.variants.primaryDark1};
        border-radius: 50%;
        position: absolute;
        top: 1rem;
        left: 0;
      }
    }
  }

  @media (max-width: 1023px) {
    font-size: 1rem;
    line-height: 1.75rem;
    &.open {
      padding: 0 1.875rem 1.25rem;
    }
  }
`
