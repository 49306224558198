import styled from '@emotion/styled'
import { FlexBox } from 'app/components/Layout/FlexBox'
import React, { memo } from 'react'

export interface Props {
  category?: string
  discount?: string
  validity?: string
}

export const Head = memo(function Head({
  category,
  discount,
  validity,
}: Props) {
  if (!category || !discount || !validity) {
    return null
  }

  return (
    <Container dial={5} row stretch wrap>
      {category && validity ? (
        <LeftSide dial={5} row wrap>
          {category ? <Category>{category}</Category> : null}
          {validity ? <Validity>{validity}</Validity> : null}
        </LeftSide>
      ) : null}
      {discount ? (
        <RightSide>
          <Discount>{discount}</Discount>
        </RightSide>
      ) : null}
    </Container>
  )
})

const Container = styled(FlexBox)`
  text-align: center;

  > div {
    flex: 1;
    padding: 3.25rem 3.333vw 3.1875rem;
  }

  @media (max-width: 1023px) {
    > div {
      flex: auto;
      padding: 1.875rem;
    }
  }
`

const LeftSide = styled(FlexBox)`
  color: ${({ theme }) => theme.colors.variants.neutralDark2};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 0.875rem;
  font-weight: 700;
  letter-spacing: 0.0875rem;
  line-height: 1.25rem;
  text-transform: uppercase;

  > div {
    display: flex;
    align-items: center;
    &:nth-of-type(2) {
      &:before {
        content: '';
        width: 0.375rem;
        height: 0.375rem;
        background: ${({ theme }) => theme.colors.variants.neutralDark2};
        border-radius: 50%;
        margin: 0 1.6875rem;
      }
    }
  }

  @media (max-width: 1023px) {
    width: 100%;
    order: 2;
  }
`

const Category = styled.div``

const Validity = styled.div``

const RightSide = styled.div`
  background: ${({ theme }) => theme.colors.variants.neutralDark2};
  color: ${({ theme }) => theme.colors.variants.neutralLight4};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 1.25rem;
  line-height: 1.6875rem;

  @media (max-width: 1023px) {
    width: 100%;
  }
`

const Discount = styled.div``
