import styled from '@emotion/styled'
import { TriangleRight } from 'app/components/Icons'
import React, { memo } from 'react'

export interface Props {
  list?: string[]
  title?: string
}

export const Includes = memo(function Includes({ list, title }: Props) {
  if (!list || !title) {
    return null
  }

  return (
    <Container>
      {title ? <Title>{title}</Title> : null}
      {list
        ? list.map((item, index) => (
            <Item key={index}>
              <TriangleRight />
              {
                // @ts-ignore
                item.label
              }
            </Item>
          ))
        : null}
    </Container>
  )
})

const Container = styled.div`
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  margin-top: 3.625rem;
`

const Title = styled.h3`
  color: ${({ theme }) => theme.colors.variants.neutralDark2};
  font-size: 0.875rem;
  font-weight: 700;
  letter-spacing: 0.0875rem;
  line-height: 1.25rem;
  margin-bottom: 1.25rem;
  text-transform: uppercase;
`

const Item = styled.div`
  color: ${({ theme }) => theme.colors.variants.neutralDark5};
  font-size: 1.125rem;
  line-height: 2rem;
  margin-top: 0.75rem;
  padding-left: 18px;
  position: relative;

  svg {
    width: auto;
    height: 0.5rem;
    fill: ${({ theme }) => theme.colors.variants.primaryDark1};
    position: absolute;
    top: 0.875rem;
    left: 0;
  }

  @media (max-width: 1023px) {
    font-size: 1rem;
    line-height: 1.75rem;
    svg {
      top: 0.75rem;
    }
  }
`
